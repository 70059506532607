
import { signInAnonymously } from "firebase/auth";
import { createContext, lazy, Suspense, useCallback, useEffect, useLayoutEffect, useMemo, useState } from "react";
import { Route, Routes, useLocation, useSearchParams } from "react-router-dom";
import { auth, firestore } from "./services/firebase";
import LoadingIndicator from "./widgets/LoadingIndicator";
import ConsentBanner from "./widgets/ConsentBanner";
import Navbar from "widgets/Navbar";
import Footer from "widgets/Footer";
import { collection, getDocs } from "firebase/firestore";
import axios from "axios";
import "./webapp.css";
import { ErrorBoundary } from "react-error-boundary";
import ErrorPage from "pages/website/misc/Error";

const Checkout = lazy(() => import('pages/webapp/Checkout'));
const Discover = lazy(() => import('pages/webapp/Discover'));
const Event = lazy(() => import('pages/webapp/Event'));
const Artist = lazy(() => import('pages/webapp/Artist'));
const Payment = lazy(() => import('pages/webapp/Payment'));
const OrganizerInfo = lazy(() => import('pages/website/organizers/Organizer'));
const Home = lazy(() => import('pages/Home'));
const Ticketing = lazy(() => import('pages/website/organizers/Ticketing'));
const Marketing = lazy(() => import('pages/website/organizers/Marketing'));
const Pricing = lazy(() => import('pages/website/organizers/Pricing'));
const Statistics = lazy(() => import('pages/website/organizers/Statistics'));
const Website = lazy(() => import('pages/website/organizers/Website'));
const Festivals = lazy(() => import('pages/webapp/Festivals'));
const AboutUs = lazy(() => import('pages/website/about/AboutUs'));
const Team = lazy(() => import('pages/website/about/Team'));
const Faq = lazy(() => import('pages/website/about/Faq'));
const Contact = lazy(() => import('pages/website/about/Contact'));
const LegalNotice = lazy(() => import('pages/website/legal/LegalNotice'));
const Privacy = lazy(() => import('pages/website/legal/PrivacyPolicy'));
const PrivacyApp = lazy(() => import('pages/website/legal/PrivacyPolicyApp'));
const TermsDashboard = lazy(() => import('pages/website/legal/TermsOfServiceDashboard'));
const TermsTicketing = lazy(() => import('pages/website/legal/TermsOfServiceTicketing'));
const Press = lazy(() => import('pages/website/about/Press'));
const Download = lazy(() => import('pages/website/misc/Download'));
const Organizer = lazy(() => import('pages/webapp/Organizer'));
const NotFound = lazy(() => import('pages/website/misc/404'));
const EventManagement = lazy(() => import('pages/website/organizers/EventManagement'));
const Clubs = lazy(() => import('pages/webapp/Clubs'));
const Organizers = lazy(() => import('pages/webapp/Organizers'));
const Article = lazy(() => import('pages/webapp/Article'));
const Articles = lazy(() => import('pages/webapp/Articles'));

export const CityContext = createContext({
    cities: null,
    selectedCity: null,
    selectedCityDoc: null,
    setSelectedCity: null,
});

export const CategoryContext = createContext({
    genres: null,
    types: null,
    locations: null,
    categories: null,
    themes: null,
});

export default function App() {
    const [searchParams] = useSearchParams();

    useEffect(() => { signInAnonymously(auth) }, []);

    //scroll to top on route change
    const location = useLocation();
    useLayoutEffect(() => {
        document.documentElement.scrollTo(0, 0);
    }, [location.pathname]);

    //ticket page determination
    const isTicketPage = useMemo(() => window.location.hostname === 't.elgio.de', []);

    //city context stuff
    const [cities, setCities] = useState(null);

    useEffect(() => { 
        getDocs(
            collection(firestore, 'cities')
        ).then(snap => setCities(snap.docs.filter(e => !e.data().coming_soon && !e.data().hidden)));
    }, []);

    const [selectedCity, setSelectedCity] = useState('cologne');
    const getDistance = (lat1, lon1, lat2, lon2) => {
        const R = 6371; // Radius of the Earth in km
        const dLat = (lat2 - lat1) * (Math.PI / 180);
        const dLon = (lon2 - lon1) * (Math.PI / 180);
        const a =
          Math.sin(dLat / 2) * Math.sin(dLat / 2) +
          Math.cos((lat1 * Math.PI) / 180) * Math.cos((lat2 * Math.PI) / 180) *
          Math.sin(dLon / 2) * Math.sin(dLon / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const distance = R * c;
        return distance;
      };
    const selectCityClosestTo = useCallback((latitude, longitude) => {
        if(!cities) return;

        let closestCity = null;
        let closestDistance = Infinity;

        cities.forEach(city => {
            const { location } = city.data();
            const distance = getDistance(latitude, longitude, location.latitude, location.longitude);
            if (distance < closestDistance) {
                closestDistance = distance;
                closestCity = city.id;
            }
        });

        setSelectedCity(closestCity);
    }, [cities]);
    useEffect(() => { (async () => {
        let location = await new Promise(r => navigator.permissions.query({ name: 'geolocation' }).then(permissionStatus => {
            if (permissionStatus.state === 'granted') {
                navigator.geolocation.getCurrentPosition(
                    position => r([position.coords.latitude, position.coords.longitude]),
                    r(null),
                );
            } else {
                r(null);
            }
        }));
        if(!location) location = await axios.get('https://us-central1-elgio-invited.cloudfunctions.net/service-getIpLocation')
            .then(e => [e.data.latitude, e.data.longitude])
            .catch(() => [50.938361, 6.959974]);
        selectCityClosestTo(...location);
    })() }, [selectCityClosestTo]);
    const selectedCityDoc = useMemo(() => cities?.find(e => e.id === selectedCity), [cities, selectedCity]);

    //category context stuff
    const [genres, setGenres] = useState(null);
    const [types, setTypes] = useState(null);
    const locations = useMemo(() => types?.filter(e => e.data().type === 'location'), [types]);
    const categories = useMemo(() => types?.filter(e => e.data().type === 'category'), [types]);
    const themes = useMemo(() => types?.filter(e => e.data().type === 'theme'), [types]);
    useEffect(() => {
        getDocs(
            collection(firestore, 'genres')
        ).then(snap => setGenres(snap.docs));
        getDocs(
            collection(firestore, 'types')
        ).then(snap => setTypes(snap.docs));
    }, []);
  
    return <div className="b-black text-white min-h-full flex justify-center">
        <div className="b-black w-full">
            {!searchParams.has('hide_navigation') && <ConsentBanner />}
            <CityContext.Provider value={{ cities, selectedCity, selectedCityDoc, setSelectedCity }}>
                <CategoryContext.Provider value={{ genres, types, locations, categories, themes }}>
                    <div className="flex flex-col h-full">
                        {!searchParams.has('hide_navigation') && !isTicketPage && <Navbar />}
                        <ErrorBoundary fallbackRender={({ error }) => <ErrorPage error={error} />}>
                            {isTicketPage ? <Routes>
                                <Route path="/:id/checkout" element={<Suspense fallback={<LoadingIndicator />}><Checkout /></Suspense>} />
                                <Route path="/:id" element={<Suspense fallback={<LoadingIndicator />}><Event /></Suspense>} />
                                <Route path="/payment/:id" element={<Suspense fallback={<LoadingIndicator />}><Payment /></Suspense>} />
                                <Route path="*" element={<Suspense fallback={<LoadingIndicator />}><Redirect to={'https://elgio.de' + location.pathname} /></Suspense>}/>
                                <Route path="/legal-notice" element={<Suspense fallback={<LoadingIndicator />}><LegalNotice/></Suspense>} />
                                <Route path="/privacy-policy" element={<Suspense fallback={<LoadingIndicator />}><Privacy/></Suspense>} />
                            </Routes> : <Routes>
                                <Route path="/article/:id/*?" element={<Suspense fallback={<LoadingIndicator />}><Article /></Suspense>} />
                                <Route path="/city/:id/*?" element={<Suspense fallback={<LoadingIndicator />}><Discover /></Suspense>} />
                                <Route path="/event/:id/checkout" element={<Suspense fallback={<LoadingIndicator />}><Checkout /></Suspense>} />
                                <Route path="/event/:id" element={<Suspense fallback={<LoadingIndicator />}><Event /></Suspense>} />
                                <Route path="/recurring-event/:id" element={<Suspense fallback={<LoadingIndicator />}><Event recurring={true} /></Suspense>} />
                                <Route path="/artist/:id" element={<Suspense fallback={<LoadingIndicator />}><Artist/></Suspense>} />
                                <Route path="/payment/:id" element={<Suspense fallback={<LoadingIndicator />}><Payment /></Suspense>} />
                                <Route path="/for-organizers" element={<Suspense fallback={<LoadingIndicator />}><OrganizerInfo/></Suspense>}/>
                                <Route path="/" element={<Suspense fallback={<LoadingIndicator />}><Home /></Suspense>} />
                                <Route path="/for-organizers/ticketing" element={<Suspense fallback={<LoadingIndicator />}><Ticketing /></Suspense>}/>
                                <Route path="/for-organizers/marketing" element={<Suspense fallback={<LoadingIndicator />}><Marketing /></Suspense>} />
                                <Route path="/for-organizers/event-management" element={<Suspense fallback={<LoadingIndicator />}><EventManagement/></Suspense>} />
                                <Route path="/for-organizers/pricing" element={<Suspense fallback={<LoadingIndicator />}><Pricing/></Suspense>} />
                                <Route path="/for-organizers/statistics" element={<Suspense fallback={<LoadingIndicator />}><Statistics/></Suspense>} />
                                <Route path="/for-organizers/website" element={<Suspense fallback={<LoadingIndicator />}><Website/></Suspense>} />
                                <Route path="/discover/*?" element={<Suspense fallback={<LoadingIndicator />}><Discover/></Suspense>} />
                                <Route path="/festivals/*?" element={<Suspense fallback={<LoadingIndicator />}><Festivals/></Suspense>} />
                                <Route path="/clubs" element={<Suspense fallback={<LoadingIndicator />}><Clubs /></Suspense>} />
                                <Route path="/organizers" element={<Suspense fallback={<LoadingIndicator />}><Organizers /></Suspense>} />
                                <Route path="/about-us" element={<Suspense fallback={<LoadingIndicator />}><AboutUs/></Suspense>} />
                                <Route path="/team" element={<Suspense fallback={<LoadingIndicator />}><Team/></Suspense>} />
                                <Route path="/faq" element={<Suspense fallback={<LoadingIndicator />}><Faq/></Suspense>} />
                                <Route path="/contact" element={<Suspense fallback={<LoadingIndicator />}><Contact/></Suspense>} />
                                <Route path="/articles" element={<Suspense fallback={<LoadingIndicator />}><Articles/></Suspense>} />
                                <Route path="/legal-notice" element={<Suspense fallback={<LoadingIndicator />}><LegalNotice/></Suspense>} />
                                <Route path="/privacy-policy" element={<Suspense fallback={<LoadingIndicator />}><Privacy/></Suspense>} />
                                <Route path="/privacy-policy-app" element={<Suspense fallback={<LoadingIndicator />}><PrivacyApp/></Suspense>} />
                                <Route path="/terms-of-service-dashboard" element={<Suspense fallback={<LoadingIndicator />}><TermsDashboard/></Suspense>} />
                                <Route path="/terms-of-service-ticketing" element={<Suspense fallback={<LoadingIndicator />}><TermsTicketing/></Suspense>} />
                                <Route path="/press" element={<Suspense fallback={<LoadingIndicator />}><Press/></Suspense>} />
                                <Route path="/download" element={<Suspense fallback={<LoadingIndicator />}><Download/></Suspense>} />
                                <Route path="/organizer/:id" element={<Suspense fallback={<LoadingIndicator />}><Organizer/></Suspense>} />
                                <Route path="*" element={<Suspense fallback={<LoadingIndicator />}><NotFound/></Suspense>} />
                            </Routes>}
                        </ErrorBoundary>
                        {!searchParams.has('hide_navigation') && <Footer minimal={isTicketPage} />}
                    </div>
                </CategoryContext.Provider>
            </CityContext.Provider>
        </div>
    </div>;
}

function Redirect({ to }) {
    window.location.replace(to);
    return <LoadingIndicator />;
}
