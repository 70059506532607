import { rightIcon } from 'icons'
import React from 'react'
import { Link } from 'react-router-dom'
import './404.css'
import StackTracey from 'stacktracey'

export default function ErrorPage ({ error }){

    const stack = new StackTracey(error);
    const topItem = stack.items[0];

    return (
        <div className='NotFoundBox'>
            <div className='errorBox'>
                <div className="errorText">Sorry, beim Anzeigen dieser Seite gab es einen Fehler </div>
                <div className='errorTextSmall'>Error-Code: {btoa(error.name + ':' + topItem.fileName + ':' + topItem.line)}</div>
                <div className='errorTextSmall'>Bitte teile uns diesen Errorcode mit, falls du dem Support (<a href="mailto:support@elgio.de">support@elgio.de</a>) diesen Fehler melden willst.</div>
            </div>
            <Link to={"/"} className='HomeLink'>
                <div>Home</div>
                <div style={{fontSize: "var(--fontsize-large)"}}>{rightIcon}</div>
            </Link>
        </div>
    )
}