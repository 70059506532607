/* LIST OF ICONS */

import { faAndroid, faApple, faFacebook, faGithub, faInstagram, faLinkedin, faSearchengin, faSoundcloud, faSpotify, faTiktok, faTwitter, faWhatsapp, faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faBullhorn, faCalendar, faCalendarDays, faChartPie, faCheck, faChevronDown, faChevronLeft, faChevronRight, faCircleInfo, faCity, faCocktail, faDoorOpen, faEdit, faEnvelope, faGlobe, faImages, faLink, faLocationPinLock, faMagnifyingGlass, faMapMarkedAlt, faMicrophone, faMobileScreen, faMusic, faPause, faPhone, faPhotoFilm, faPlay, faPlus, faShare, faSignal, faSignature, faStar, faTicket, faTicketAlt, faTshirt, faUserLock, faUsersCog, faXmark } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";


/* direction arrows */
export const downIcon = <FontAwesomeIcon icon={faChevronDown} />;
export const rightIcon = <FontAwesomeIcon icon={faChevronRight} />;
export const leftIcon = <FontAwesomeIcon icon={faChevronLeft} />;

/* action icons */
export const plusIcon = <FontAwesomeIcon icon={faPlus} />;
export const cancelIcon = <FontAwesomeIcon icon={faXmark} />;
export const checkmarkIcon = <FontAwesomeIcon icon={faCheck} />;
export const shareIcon = <FontAwesomeIcon icon={faShare} />;
export const playIcon = <FontAwesomeIcon icon={faPlay} />;
export const pauseIcon = <FontAwesomeIcon icon={faPause} />;
export const searchIcon = <FontAwesomeIcon icon={faMagnifyingGlass} />;

/* brands */
export const iosIcon = <FontAwesomeIcon icon={faApple}/>;
export const androidIcon = <FontAwesomeIcon icon={faAndroid}/>;
export const instagramIcon = <FontAwesomeIcon icon={faInstagram}/>;
export const spotifyIcon = <FontAwesomeIcon icon={faSpotify}/>;
export const twitterIcon = <FontAwesomeIcon icon={faTwitter}/>;
export const soundcloudIcon = <FontAwesomeIcon icon={faSoundcloud}/>;
export const linkedinIcon = <FontAwesomeIcon icon={faLinkedin}/>;
export const githubIcon = <FontAwesomeIcon icon={faGithub}/>;
export const facebookIcon = <FontAwesomeIcon icon={faFacebook}/>;
export const whatsappIcon = <FontAwesomeIcon icon={faWhatsapp}/>;
export const youtubeIcon = <FontAwesomeIcon icon={faYoutube}/>;
export const tiktokIcon = <FontAwesomeIcon icon={faTiktok}/>;

/* symbols */
export const infoIcon = <FontAwesomeIcon icon={faCircleInfo}/>;
export const emailIcon = <FontAwesomeIcon icon={faEnvelope}/>;
export const phoneIcon = <FontAwesomeIcon icon={faPhone} />;
export const cityIcon = <FontAwesomeIcon icon={faCity}/>;
export const secretLocationIcon = <FontAwesomeIcon icon={faLocationPinLock}/>;

/* event erstellen inputs */
export const titelbildIcon = <FontAwesomeIcon icon={faImages}/>;
export const nameIcon = <FontAwesomeIcon icon={faSignature}/>;
export const datumIcon = <FontAwesomeIcon icon={faCalendar}/>;
export const adresseIcon = <FontAwesomeIcon icon={faMapMarkedAlt}/>;
export const genreIcon = <FontAwesomeIcon icon={faMusic}/>;
export const lineupIcon = <FontAwesomeIcon icon={faMicrophone}/>;
export const minAlterIcon = <FontAwesomeIcon icon={faUserLock}/>;
export const dresscodeIcon = <FontAwesomeIcon icon={faTshirt}/>;
export const getraenkeIcon = <FontAwesomeIcon icon={faCocktail}/>;
export const eintrittIcon = <FontAwesomeIcon icon={faTicketAlt}/>;
export const beschreibungIcon = <FontAwesomeIcon icon={faEdit}/>;
export const coVeranstalterIcon = <FontAwesomeIcon icon={faUsersCog}/>;
export const crossPlattformIcon = <FontAwesomeIcon icon={faPhotoFilm}/>;
export const specialIcon = <FontAwesomeIcon icon={faStar}/>;
export const storyLinkIcon = <FontAwesomeIcon icon={faLink}/>;
export const socialMediaIcon = <FontAwesomeIcon icon={faPhotoFilm}/>;

/* website related */
export const domainIcon = <FontAwesomeIcon icon={faGlobe}/>;
export const seoIcon = <FontAwesomeIcon icon={faSearchengin}/>;
export const mobileIcon = <FontAwesomeIcon icon={faMobileScreen}/>;
export const uptimeIcon = <FontAwesomeIcon icon={faSignal}/>;

/* event page */
export const doorsOpenIcon = <FontAwesomeIcon icon={faDoorOpen}/>;

/* suboption */
export const eventsIcon = <FontAwesomeIcon icon={faCalendarDays}/>;
export const websiteIcon = <FontAwesomeIcon icon={faGlobe}/>;
export const statisticsIcon = <FontAwesomeIcon icon={faChartPie}/>;
export const ticketingIcon = <FontAwesomeIcon icon={faTicket}/>;
export const marketingIcon = <FontAwesomeIcon icon={faBullhorn}/>;